<template>
  <v-container class="leaflet-view">
    <SelectedStore />
    <h4 class="text mt-4">{{ $t("leaflet.chooseStore") }}</h4>
    <v-row align="center">
      <v-col class="d-flex" cols="6">
        <v-select
          :items="provinceList"
          v-model="provinceId"
          item-text="name"
          item-value="id"
          placeholder="Provincia"
          outlined
          rounded
          hide-details
          class="custom-select"
          @change="filterByProvince()"
          :no-data-text="$t('$vuetify.noDataText')"
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="6">
        <v-select
          :items="availableCity"
          v-model="city_id"
          item-text="name"
          item-value="id"
          placeholder="Città"
          outlined
          rounded
          hide-details
          class="custom-select"
          @change="findWarehouse()"
          :no-data-text="$t('$vuetify.noDataText')"
        ></v-select>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      grow
      color="white"
      background-color="grey lighten-1"
      class="segment-style-tabs mt-5"
    >
      <v-tab :ripple="false"
        ><span>{{ $t("leaflet.selectionType.list") }}</span></v-tab
      >
      <v-tab :ripple="false"
        ><span>{{ $t("leaflet.selectionType.map") }}</span></v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <AddressList :addressList="warehouseList" />
      </v-tab-item>
      <v-tab-item :touch="false">
        <GmapMap
          ref="mapRef"
          :center="{ lat: userLocation.lat, lng: userLocation.lng }"
          :zoom="userLocation.zoom"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false
          }"
          style="width: 100%; height: 500px"
        >
          <GmapCluster
            :imagePath="'/img_layout/map_pointer_placeholder'"
            :zoomOnClick="true"
            :gridSize="40"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in warehouseList"
              :position="
                google &&
                  new google.maps.LatLng(
                    m.address.latitude,
                    m.address.longitude
                  )
              "
              :clickable="true"
              icon="/img_layout/map_pointer.png"
              @click="toggleInfoWindow(m, index)"
            />
            <gmap-info-window
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
              <div v-html="infoOptions.content"></div>
              <v-btn
                small
                rounded
                block
                color="primary"
                class="my-1 info-window-btn"
                @click="openDetailsPage"
                >SCHEDA PUNTO VENDITA</v-btn
              >
              <v-btn
                small
                rounded
                block
                color="primary"
                outlined
                class="my-1 info-window-btn"
                @click="openGoogleMaps"
                >INDICAZIONI</v-btn
              >
            </gmap-info-window>
          </GmapCluster>
          <gmap-marker v-if="showUserLocation" :position="userLocation" />
        </GmapMap>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<style lang="scss">
.leaflet-view {
  .custom-select {
    transform: scaleY(0.9);
  }
  .custom-select.v-text-field--outlined fieldset {
    border: 1px solid var(--v-grey-lighten3) !important;
  }
  .info-window-btn {
    .v-btn__content {
      font-size: 12px;
    }
  }
}
.cluster div {
  color: var(--v-secondary-base) !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  width: 52px !important;
  line-height: 54px !important;
}
#infoWindowDialog {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-primary-base);
  }

  h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: var(--v-primary-base);
    margin-bottom: 0;
  }

  a {
    cursor: pointer;
  }
}
</style>
<script>
import AddressList from "@/components/leaflet/AddressList.vue";
import AddressService from "~/service/addressService";
import { gmapApi } from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import { calculateHourRow } from "@/warehouseHoursHelper";
import SelectedStore from "@/components/leaflet/SelectedStore.vue";
export default {
  components: { AddressList, GmapCluster, SelectedStore },
  name: "Leaflet",
  data() {
    return {
      provinceId: null,
      city_id: null,
      parent_address_class_id: null,
      region_id: null,
      addressToSearch: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      clusterStyles: [
        {
          textColor: "red",
          url: "/img_layout/map_pointer_placeholder1.png"
        }
      ],
      warehouseList: [],
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null,
      offsetDay: 1,
      todayHours: null,
      tab: null,
      provinceList: [],
      cityList: [],
      userLocation: { lat: 43.10678580085466, lng: 12.38307931024827, zoom: 8 },
      showUserLocation: false
    };
  },
  computed: {
    google: gmapApi,
    availableCity() {
      return this.cityList;
    }
  },
  methods: {
    async findWarehouse() {
      let searchParams = {
        province_id: this.provinceId ? this.provinceId : undefined,
        parent_address_class_id: this.parentAddressClassId,
        region_id: this.regionId,
        city_id: this.city_id ? this.city_id : undefined,
        sort: "city_asc"
      };
      if (this.addressToSearch) {
        searchParams["parent_latitude"] = this.addressToSearch.latitude;
        searchParams["parent_longitude"] = this.addressToSearch.longitude;
        searchParams["parent_distance"] = this.addressToSearch.distance;
      }
      if (searchParams.province_id === "null") {
        delete searchParams.province_id;
      }
      if (searchParams.city_id === "null") {
        delete searchParams.city_id;
      }
      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
      this.provinceList = data.facets[1].values;
      this.provinceList.unshift({ id: "null", name: "Tutte" });

      const provinceValues = data.facets.find(
        item => item.parameterName === "province_id"
      );

      if (provinceValues) {
        this.provinceList = provinceValues.values;
      } else {
        this.provinceList = [];
      }

      const cityValues = data.facets.find(
        item => item.parameterName === "city_id"
      );
      if (cityValues) {
        if (this.provinceId === null) {
          this.cityList = cityValues.values;
        } else if (this.city_id === null && this.provinceId) {
          this.cityList = cityValues.values.filter(
            item => parseInt(item.filteredCount) >= 1
          );
        }
        this.cityList.unshift({ id: "null", name: "Tutte" });
      } else {
        this.cityList = [];
      }
    },
    filterByProvince() {
      this.city_id = null;
      this.findWarehouse();
    },
    toggleInfoWindow(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };
      let hours = this.calculateTime(warehouse.serviceHours);
      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Poppins, arial, sans-serif'>
          <h3 class='d-flex align-center' style='line-height: 1.2rem;'>
            ${warehouse.name}
          </h3>
          <div class='mb-1'>
            <strong>${warehouse.address.city} - ${warehouse.address.province}</strong> <br/>
            ${warehouse.address.addressName} <br/>
          </div>
          <h4>ORARIO PUNTO VENDITA</h4>
          <strong>${hours.weekDay}:</strong> ${hours.hours}`;

      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
    },
    openDetails(warehouse) {
      console.log("openDetails");
      console.log(warehouse);
    },
    calculateTime(serviceHours) {
      let today = this.$dayjs();
      let todayH = calculateHourRow(serviceHours, today, this.offsetDay);
      return {
        weekDay: today.format("dddd"),
        hours: todayH ? todayH : "Chiuso"
      };
    },
    openDetailsPage() {
      this.$router.push(`/punti-vendita/${this.selectedWarehouse.warehouseId}`);
    },
    openGoogleMaps() {
      const url = `https://www.google.it/maps/dir//${this.selectedWarehouse.name}@/${this.selectedWarehouse.address.latitude},${this.selectedWarehouse.address.longitude}`;
      window.open(url, "_blank");
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              zoom: 12
            };
            this.showUserLocation = true;
          },
          error => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported");
      }
    }
  },
  mounted() {
    this.findWarehouse();
    this.getUserLocation();
  }
};
</script>
